.primaryHub:not(:nth-child(2n)):after {
  @apply content-none border-r-4 border-accent-blue md:content-['_'] h-[95%] absolute right-[-50px] top-0 skew-x-[-12deg] w-full z-10;
}

@screen lg {
  .primaryHub:not(:nth-child(4n)):after {
    @apply border-r-4 border-accent-blue content-['_'] h-[95%] absolute right-[-50px] top-0 skew-x-[-12deg] w-full z-10;
  }
}

.arrow {
  @apply pl-1 inline-block;
}

.arrow svg {
  @apply text-white inline-block;
}
