.content h1 {
  @apply text-[2.25rem] mt-4 mb-4 leading-[3rem] sm:text-[3.25rem] sm:leading-[3.5rem] sm:mt-4 sm:mb-4;
}

.content h2 {
  @apply text-[2rem] mt-8 mb-6 leading-[2.25rem];
}

.content h3 {
  @apply text-[1.375rem] mt-4 mb-4 leading-[1.75rem];
}

.content p {
  @apply mb-4;
}
