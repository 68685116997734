.item {
  @apply bg-background
    break-words
    flex
    flex-col
    cursor-pointer
    border-[1px] border-putty-300;
}

.item h3 {
  @apply m-0;
}

.trigger {
  @apply flex justify-between p-2 text-gray-dark text-base hover:no-underline;
}

.trigger svg {
  @apply h-6 w-6 text-gray-dark ml-[2.5rem];
}

.content {
  @apply p-2 text-base border-t-2;
}
