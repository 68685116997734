.searchListItem a,
.searchListItem mark {
  @apply text-black;
}
.searchListItem:hover {
  @apply bg-black;
}
.searchListItem:hover a,
.searchListItem:hover mark {
  @apply text-white;
}
