.container {
  @apply text-left m-0;
}

.container p {
  @apply mt-0 mb-4 text-base text-black;
}

.container ul {
  @apply block text-base text-black list-disc ps-10;
}

.container ul li {
  @apply list-item;
}

.container p:last-child {
  @apply mb-0;
}

.execEdDetailCenteredContent {
  @apply px-0 md:px-4 max-w-3xl mx-auto w-screen ml-[calc(-50vw+50%)] sm:ml-auto sm:w-auto;
}
