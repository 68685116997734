.item {
  @apply bg-background break-words gap-10 border border-putty-400;
}

.item h3 {
  @apply my-0;
}

.trigger {
  @apply flex justify-between py-4 px-6 cursor-pointer font-bold text-gray-800 text-lg hover:no-underline;
}

.content {
  @apply py-4 px-6 text-base border-t-2;
}
