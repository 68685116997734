.container {
  @apply text-left m-0;
}

.container p:not(.noStyle p) {
  @apply mt-0 mb-4 text-base text-black;
}

.container ul:not(.noStyle ul) {
  @apply block text-base text-black list-disc ps-10;
}

.container ul li:not(.noStyle ul li) {
  @apply list-item;
}

.container p:last-child:not(.noStyle p:last-child) {
  @apply mb-0;
}

.courseDetailCenteredContent {
  @apply px-0 md:px-4 max-w-3xl mx-auto w-screen ml-[calc(-50vw+50%)] sm:ml-auto sm:w-auto;
  max-width: 1228px;
}

.courseHero {
  background:
    linear-gradient(0deg, rgba(31, 69, 61, 0.5) 0%, rgba(31, 69, 61, 0.5) 100%),
    url("/bg-images/learn-page-header.webp"),
    lightgray 50%;
  background-size: cover;
  background-position: center;
}
