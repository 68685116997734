.breadcrumbs {
  color: white;
}
.breadcrumbs:hover {
  color: white;
}

.breadcrumb_link {
  color: white;
  text-decoration: underline;
  text-decoration-color: #00688d4d;
  transition: text-decoration-color 0.2s ease;
}

.breadcrumb_link:hover {
  color: white;
  text-decoration-color: #00688d;
}

.breadcrumbs_dark {
  color: #00262b;
}

.breadcrumbs_dark:hover {
  color: #00262b;
}

.breadcrumb_link_dark {
  color: #00262b;
  text-decoration: underline;
  text-decoration-color: #00688d4d;
  transition: text-decoration-color 0.2s ease;
}

.breadcrumb_link_dark:hover {
  color: #00262b;
  text-decoration-color: #00688d;
}
