@media (min-width: 1024px) {
  .statisticsSection {
    position: relative;
    height: 95%;
    width: 33%;
  }

  .skewedBorder {
    border-left: 4px solid #eae6e5;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: skew(-12deg);
    z-index: -1;
  }
}
