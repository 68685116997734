.subjectBreadcrumbs nav[aria-label="breadcrumb"] a {
  @apply text-primary-foreground;
}

.courseBreadcrumbs nav[aria-label="breadcrumb"] a {
  @apply text-primary;
}

.imageContainer {
  transform: skew(-12deg) translateX(50px);
  overflow: hidden;
  height: 100%;
}

.image {
  transform: skew(12deg) translateX(-50px);
  width: auto;
  height: 100%;
}
