.item {
  @apply bg-primary-500 break-words gap-10 border border-putty-400;
}

.item h3 {
  @apply my-0;
}

.trigger {
  @apply flex justify-between gap-10 py-4 px-6 cursor-pointer hover:no-underline relative;
}

.trigger span {
  @apply text-white text-lg font-bold;
}

.trigger svg {
  @apply h-4 w-4 text-white ml-0;
}

.content {
  @apply bg-white py-6 pl-6 pr-12 text-base border-t-2;
}
