.item {
  @apply border-[1px] border-putty-300 !important;
}

.trigger {
  @apply p-2 text-[1.125rem] leading-[1.75rem] text-left hover:no-underline !important;
}

.trigger svg {
  @apply h-6 w-6 text-gray-dark ml-[2.5rem];
}

.trigger h3 {
  @apply m-0 !important;
}

.content {
  @apply p-2 pl-3 border-[1px] border-putty-300;
}

.content a {
  @apply text-primary-500 hover:underline !important;
}

.content p:last-child {
  @apply pb-0 mb-0;
}
